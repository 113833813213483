import React from 'react';
import './RecipeGrid.css';

import RecipeCard from '../RecipeCard/RecipeCard';

// Grid displaying recipe cards
function RecipeGrid(props) {
    let recipeList = props.recipeList
    return (
        <div className="recipe-grid">
            {
                props.title && <h2>{props.title}</h2>
            }

            {
                recipeList.length !== 0
                    ?
                    <div>
                        {
                            recipeList.map((recipe, i) => {
                                return <RecipeCard recipe={recipe} key={i} />
                            })
                        }
                    </div>
                    :
                    <div className="empty-comment">
                        <p>No Recipes</p>
                    </div>
            }

        </div>
    )
}

export default RecipeGrid;