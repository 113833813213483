import React from 'react';
import './StepList.css';

import StepListEntry from '../StepListEntry/StepListEntry';

function StepList(props) {
    return (
        <div className="step-list">
            <h2>Steps</h2>

            {
                props.steps.map((stat, i) => {
                    return <StepListEntry stat={stat} key={i}/>
                })
            }
        </div>
    )
}

export default StepList;