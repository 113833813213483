import React, { useState } from 'react';
import './Filters.css';

import FilterList from '../FilterList/FilterList';

function Filters(props) {

    const getActiveFromQuery = () => {
        if (!props.searchQuery) {
            return []
        }

        let cuisineQuery = props.searchQuery.toString().substring(
            props.searchQuery.lastIndexOf("&cuisine=") + 9,
            props.searchQuery.lastIndexOf("&diet=")
        ).split(',')
        let dietQuery = props.searchQuery.toString().substring(
            props.searchQuery.lastIndexOf("&diet=") + 6,
            props.searchQuery.lastIndexOf("&intolerances=")
        ).split(',')
        let intolerancesQuery = props.searchQuery.toString().substring(
            props.searchQuery.lastIndexOf("&intolerances=") + 14,
            props.searchQuery.length
        ).split(',')

        return [cuisineQuery, dietQuery, intolerancesQuery]
    }
    // modifier les listes pour le props

    const [ activeFilter, setActiveFilter ] = useState(0);
    const [activeCuisines, setActiveCuisines] = useState([]);
    const [activeDiets, setctiActiveDiets] = useState([]);
    const [activeIntolerances, setActiveIntolerances] = useState([]);

    let optionsList = ["Cuisines", "Diets", "Intolerances"];
    let cuisines = ["African", "American", "British", "Cajun", "Caribbean", "Chinese", "Eastern European", "European", "French", "German"];
    let diets = ["Gluten Free","Ketogenic","Vegetarian","Lacto-Vegetarian","Ovo-Vegetarian","Vegan","Pescetarian","Paleo","Primal","Whole30"];
    let intolerances = ["Dairy","Egg","Gluten","Grain","Peanut","Seafood","Sesame","Shellfish","Soy","Sulfite","Tree Nut","Wheat"];
    let optionsEntry = [cuisines, diets, intolerances];
    let activeList = !props.searchQuery ? [activeCuisines, activeDiets, activeIntolerances] : getActiveFromQuery();
    let setActiveList = [setActiveCuisines, setctiActiveDiets, setActiveIntolerances]

    let handleClick = (i) => {
        setActiveFilter(i);
    }

    let checkTheList = (item) => {
        let e = activeList[activeFilter].find(e => e === item);
        if (activeList[activeFilter].includes(item)) {
            activeList[activeFilter].splice(activeList[activeFilter].indexOf(e), 1);
        } else {
            activeList[activeFilter].push(item);
        }
        setActiveList[activeFilter](activeList[activeFilter])
        props.setFiltersList(activeList)
    }

    return (
        <div className="filters">
            <ul>
                {
                    optionsList.map((option, i) => {
                        return <li key={i} className={activeFilter === i ? "active" : ""} onClick={() => handleClick(i)}>{option}</li>
                    })
                }
            </ul>

            <FilterList optionsEntry={optionsEntry[activeFilter]} activeList={activeList[activeFilter]} checkTheList={checkTheList} />
        </div>
    )
}

export default Filters;