import {
    BrowserRouter as Router,
    Switch,
    Route,
    Redirect
} from "react-router-dom";
import './App.css';

import FirstConfiguration from '../FirstConfiguration/FirstConfiguration';
import Navbar from '../Navbar/Navbar';
import Container from '../Container/Container';
import MainPage from '../MainPage/MainPage';
import Favorites from '../Favorites/Favorites';
import Preferences from '../Preferences/Preferences';
import SearchPage from '../SearchPage/SearchPage';
import RecipePage from '../RecipePage/RecipePage';
import NoRouteMatch from "../NoRouteMatch/NoRouteMatch";

function App() {

    let firstVisited = localStorage["visited"] ? false : true;

    return (
        <div className="app">
            <Router>
                {firstVisited && <Redirect to="/welcome" />}
                <Switch>
                    <Route path="/welcome">
                        <FirstConfiguration />
                    </Route>

                    <Route path="*">
                        <Navbar />

                        <Container>
                            <Switch>
                                <Route path="/recipe/:recipeId">
                                    <RecipePage />
                                </Route>

                                <Route path="/search/:searchQuery">
                                    <SearchPage />
                                </Route>

                                <Route path="/preferences">
                                    <Preferences />
                                </Route>

                                <Route path="/favorites">
                                    <Favorites />
                                </Route>

                                <Route exact path="/">
                                    <MainPage />
                                </Route>

                                <Route path="*">
                                    <NoRouteMatch />
                                </Route>
                            </Switch>
                        </Container>
                    </Route>
                </Switch>

            </Router>
        </div>
    );
}

export default App;
