import React from 'react';
import './Container.css';

// Component for design purpose only
function Container(props) {
    return (
        <div className="container">
            {props.children}
        </div>
    )
}

export default Container;