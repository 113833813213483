import React from 'react';
import { Link } from 'react-router-dom';
import './NavbarEntry.css';

// Link in the navbar
function NavbarEntry(props) {
    return (
        <Link to={props.link}>
            <div className={`navbar-entry${props.designed ? " designed" : ""}`} onClick={props.onClick}>
                {props.text}
            </div>
        </Link>
    )
}

export default NavbarEntry;