import React from 'react';
import Button from '../Button/Button';
import './FavButton.css';

function FavButton(props) {
    function handleFav() {
        props.onClick();

        if (!props.favorite) {
            addFav(props.recipe)
        } else {
            removeFav(props.recipe.id)
        }
    }

    function addFav(recipe) {
        let favorites = localStorage["favorites"] ? JSON.parse(localStorage["favorites"]) : [];

        let newFav = {
            id: recipe.id,
            title: recipe.title,
            image: recipe.image,
            readyInMinutes: recipe.readyInMinutes,
        }

        let e = favorites.find(e => e.id === recipe.id);
        if (!e) favorites.push(newFav)

        localStorage['favorites'] = JSON.stringify(favorites);
    }

    function removeFav(recipeId) {
        let favorites = localStorage["favorites"] ? JSON.parse(localStorage["favorites"]) : [];

        let e = favorites.find(e => e.id === recipeId);
        if (e) favorites.splice(favorites.indexOf(e), 1);

        localStorage['favorites'] = JSON.stringify(favorites);
    }

    return (
        <div className="fav-button">
            <Button onClick={() => handleFav()} text={props.favorite ? "Remove From My Favorites" : "Add To My Favorites"} outline={!props.favorite} />
        </div>
    )
}

export default FavButton;