import React from 'react';
import './Loading.css';

function Loading() {
    return (
        <div className="loading-screen">
            <img src="/img/icon.png" alt=""/>
            <p>Loading</p>
        </div>
    )
}

export default Loading;