import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import './RecipePage.css';

import FavButton from '../FavButton/FavButton';
import NutritionWidget from '../NutritionWidget/NutritionWidget';
import IngredientList from '../IngredientList/IngredientList';
import StepList from '../StepList/StepList';
import BackButton from '../BackButton/BackButton';
import NoRouteMatch from "../NoRouteMatch/NoRouteMatch";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClock } from '@fortawesome/free-regular-svg-icons';
import { faUtensils } from '@fortawesome/free-solid-svg-icons';
import Loading from '../Loading/Loading';

// Page displaying complete recipe
function RecipePage() {
    let { recipeId } = useParams();

    const [error, setError] = React.useState(null);
    const [isLoaded, setIsLoaded] = React.useState(false);
    const [recipe, setRecipe] = React.useState(0);
    const [isFavorite, setIsFavorite] = React.useState((localStorage["favorites"] ? JSON.parse(localStorage["favorites"]) : []).find(e => e.id === parseInt(recipeId)) != null);

    let toggleFavorite = () => {
        setIsFavorite(!isFavorite);
    }

    // Recipe API call
    useEffect(() => {
        fetch("https://api.spoonacular.com/recipes/" + recipeId + "/information?apiKey=" + process.env.REACT_APP_API_KEY_RECIPE)
            .then(result => {
                if (result.status === 200) {
                    return Promise.resolve(result)
                }
                else {
                    return Promise.reject(new Error(result.status))
                }
            })
            .then(result => result.json())
            .then(result => {
                setIsLoaded(true);
                setRecipe(result);

                document.title = `${result.title} | Dans le meal`;
            })
            .catch(err => {
                setIsLoaded(true);
                setError(err.message);

                document.title = `Error | Dans le meal`;
            })
    }, [recipeId])

    if (!isLoaded) {
        return <Loading />
    } else if (error || recipe.id == null) {
        return <NoRouteMatch code={error} />
    } else {
        return (
            <div id="recipe-page">
                <BackButton />

                <div className="recipe-presentation">
                    <div className="recipe-infos">
                        <h1>{recipe.title}</h1>
                        {
                            recipe.hasOwnProperty('sourceName') && <p>{recipe.sourceName}</p>
                        }

                        <div>
                            <FontAwesomeIcon icon={faUtensils} />
                            <p>{recipe.servings} Servings</p>
                            <FontAwesomeIcon icon={faClock} />
                            <p>{Math.floor(recipe.readyInMinutes / 60)} hour {recipe.readyInMinutes % 60} minutes</p>
                        </div>

                        <FavButton favorite={isFavorite} onClick={toggleFavorite} recipe={recipe} />
                    </div>

                    <div className="recipe-thumbnail">
                        <div>
                            <img src={recipe.image} alt="Dish" />
                        </div>
                    </div>
                </div>

                <NutritionWidget recipeId={recipeId} /> <br />

                <IngredientList ingredients={recipe.extendedIngredients} /> <br />

                {
                    recipe.analyzedInstructions[0] && <StepList steps={recipe.analyzedInstructions[0].steps} />
                }
            </div>
        )
    }
}

export default RecipePage;