import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { createRef, useEffect, useState } from 'react';
import Button from '../Button/Button';
import PreferenceEntry from '../PreferenceEntry/PreferenceEntry';
import './PreferenceChoice.css';

// List of possibilities for one parameter
function PreferenceChoice(props) {

    const [ scroll, setScroll ] = useState(0);
    const [ previousScroll, setPreviousScroll ] = useState(0);

    const [ wrapperWidth, setWrapperWidth ] = useState(0);
    const [ entriesWidth, setEntriesWidth ] = useState(0);

    let preferences = localStorage["preferences"] ? JSON.parse(localStorage["preferences"]) : [];

    function select(entry) {
        let e = preferences.find(e => e.name === entry.name);
        if (e) preferences.splice(preferences.indexOf(e), 1);
        else preferences.push(entry);
        localStorage["preferences"] = JSON.stringify(preferences);
        props.updatePreferences();
    }

    let ref = createRef({});

    let entriesScroll = (direction) => {
        let scrollVal;
        if (direction > 0) {
            scrollVal = scroll - wrapperWidth / 2

            if (scrollVal < -entriesWidth+wrapperWidth) {
                scrollVal = -entriesWidth+wrapperWidth
            }
        }
        else {
            scrollVal = scroll + wrapperWidth / 2

            if (scrollVal > 0) {
                scrollVal = 0
            }
        }

        setPreviousScroll(scroll)
        setScroll(scrollVal)
    }

    useEffect(() => {
        setPreviousScroll(0);
        setScroll(0);

        setWrapperWidth(ref.current.querySelector(".wrapper").offsetWidth);
        setEntriesWidth(ref.current.querySelector(".entries").offsetWidth);
        // eslint-disable-next-line
    }, [props.type])

    return (
        <div className="preference-choice" ref={ref}>
            <h1>{props.type.question}</h1>

            <div className="wrapper">
                { scroll < 0 && 
                    <div className="left-btn">
                        <Button text={<FontAwesomeIcon icon={faChevronLeft} />} onClick={() => entriesScroll(-1)} />
                    </div>
                }

                <div className="entries" style={{left: scroll, transitionDuration: `${Math.abs(previousScroll - scroll) * 500 / 537}ms`}}>
                    {
                        props.type.preferences.map((entry, i) => {
                            return (
                                <PreferenceEntry key={`${props.step}_${i}`} onClick={() => select(entry)} preference={entry} active={props.selectedPreferences.filter(e => e.name === entry.name).length > 0}>
                                    {
                                        props.step === 1 &&
                                        <img src={`/img/preferences/${entry.name.toLowerCase()}.jpg`} alt=""/>
                                    }
                                </PreferenceEntry>
                            )
                        })
                    }
                </div>

                { scroll > -entriesWidth+wrapperWidth &&
                    <div className="right-btn">
                        <Button text={<FontAwesomeIcon icon={faChevronRight} />} onClick={() => entriesScroll(1)} />
                    </div>
                }
            </div>

        </div>
    )
}

export default PreferenceChoice;