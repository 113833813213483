import React from 'react';
import './Button.css';

// General button component
function Button(props) {
    return (
        <div onClick={props.onClick} className={`button${props.className ? " " + props.className : ""}${props.outline ? " outline" : ""}`}>
            <button>{props.text}</button>
        </div>
    )
}

export default Button;