/* eslint-disable array-callback-return */
import React, { useState } from 'react';
import {Link, useHistory} from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faSlidersH } from '@fortawesome/free-solid-svg-icons';
import './RecipeSearch.css';

import Filters from '../Filters/Filters';
import Button from '../Button/Button';

// Search widget
function RecipeSearch(props) {
    let history = useHistory()

    const [ filtersOpen, setFiltersOpen ] = useState(false);
    const [recipe, setRecipe] = useState("");
    const [filtersList, setFiltersList] = useState([[], [], []])

    // ?query=&cuisine=&diet=&intolerances=

    let searchForge = () => {
        let cuisines = []
        let diet = []
        let intolerances = []
        filtersList[0].map((item) => {
            cuisines.push(item)
        })
        filtersList[1].map((item) => {
            diet.push(item)
        })
        filtersList[2].map((item) => {
            intolerances.push(item)
        })
        let query = ''
        query = '/search/query=' + recipe + '&cuisine=' + cuisines.join(',') + '&diet=' + diet.join(',') + '&intolerances=' + intolerances.join(',')
        return query
    }
    let handleInputChange = e => {
        setRecipe(e.target.value);
    };
    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            // Redirect to searchForge query
            history.push(searchForge().toString())
        }
    }

    return (
        <div className="recipe-search">
            <div className="search-input">
                <div className="input">
                    <Link to={searchForge}>
                        <FontAwesomeIcon icon={faSearch} />
                    </Link>

                    <input type="text" placeholder="Search recipes" onChange={handleInputChange} onKeyDown={handleKeyDown} />
                </div>

                <Button className={filtersOpen ? "active" : ""} text={<FontAwesomeIcon icon={faSlidersH} />} onClick={() => setFiltersOpen(!filtersOpen)}/>

            </div>

            {
                filtersOpen && <Filters setFiltersList={setFiltersList} searchQuery={props.searchQuery} />
            }
        </div>
    )
}

export default RecipeSearch;