import React, { useEffect } from 'react';
import './Favorites.css';
import RecipeGrid from "../RecipeGrid/RecipeGrid";

// Page of the registered recipes
function Favorites() {
    const [favorites] = React.useState(localStorage["favorites"] ? JSON.parse(localStorage["favorites"]) : []);

    useEffect(() => {
        document.title = `Favorites | Dans le meal`;
    }, [])

    return (
        <div id="favorites">
            <RecipeGrid title="My Favorites" recipeList={favorites} />
        </div>
    )
}

export default Favorites;