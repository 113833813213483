import React from 'react';
import './IngredientList.css';

import IngredientListEntry from '../IngredientListEntry/IngredientListEntry';

function IngredientList(props) {
    return (
        <div className="ingredient-list">
            <h2>Ingredients</h2>

            {
                props.ingredients.map((stat, i) => {
                    return <IngredientListEntry stat={stat} key={i}/>
                })
            }
        </div>
    )
}

export default IngredientList;