import React, { useState, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import './FirstConfiguration.css';

import PreferenceChoice from '../PreferenceChoice/PreferenceChoice';
import Button from '../Button/Button';

import cuisines from '../../assets/preferences/cuisines.json'
import intolerances from '../../assets/preferences/intolerances.json'
import diets from '../../assets/preferences/diets.json'

// Preferences configuration page when first visit
function FirstConfiguration() {

    const history = useHistory();
    const [type, setType] = useState(cuisines);

    let pref = localStorage["preferences"] ? JSON.parse(localStorage["preferences"]) : [];
    // eslint-disable-next-line
    const [preferences, setPreferences] = useState(pref);

    let step = useRef(1);

    function updatePreferences() {
        setPreferences(JSON.parse(localStorage["preferences"]));
    }

    function next() {
        change(++step.current);
    }

    function previous() {
        change(--step.current);
    }

    function done() {
        if (!localStorage["preferences"]) localStorage["preferences"] = JSON.stringify([]);
        localStorage["visited"] = true;
        history.push('/');
    }

    function change() {
        switch (step.current) {
            case 1:
                setType(cuisines);
                break;
            case 2:
                setType(intolerances);
                break;
            case 3:
                setType(diets);
                break;
            default:
                setType(cuisines);
        }
    }

    return (
        <div id="conf-page">
            <img className="logo" src="/img/logo.png" alt="Dans le Meal Logo"/>
            <PreferenceChoice type={type} selectedPreferences={preferences} updatePreferences={updatePreferences} step={step.current}/>
            <div className="buttons">
                {step.current > 1 && <Button text="Previous" onClick={previous} />}
                {step.current < 3 && <Button text="Next" onClick={next} />}
                {step.current >= 3 && <Button text="Done" onClick={done} />}
            </div>
        </div>
    )
}

export default FirstConfiguration;