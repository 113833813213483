import React from 'react';
import './StepListEntry.css';

function StepListEntry(props) {
    return (
        <div className="step-list-entry">
            <p>Step <span>{ props.stat.number }</span></p>
            <p>{ props.stat.step }</p>
        </div>
    )
}

export default StepListEntry;