import React from 'react';
import './NutritionStat.css';

// Widget displaying nutrition infos
function NutritionStat(props) {

    let type = props.stat["name"];
    let quantityStr = props.stat["amount"];
    let quantity = parseInt(quantityStr.replace(/[a-z]/gi, ''));
    let dailyValue = props.stat["dv"];

    let pct = 100 - Math.round(quantity / dailyValue * 100);

    return (
        <div className="nutrition-stat" style={{ backgroundImage: `linear-gradient(#ffdce4 ${pct}%, #ff2657 ${pct}%)` }}>
            <p>{quantityStr}</p>
            <p>{type}</p>
        </div>
    )
}

export default NutritionStat;