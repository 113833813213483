import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import './FilterListEntry.css';

// 1 option of 1 filter
function FilterListEntry(props) {

    let handleClick = () => {
        props.checkTheList(props.text)
    }

    return (
        <li className={`filter-list-entry${props.checked ? " checked" : ""}`} onClick={handleClick}>
            <FontAwesomeIcon icon={faCheck} />
            {props.text}
        </li>
    )
}

export default FilterListEntry;