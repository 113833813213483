import React from 'react';
import FilterListEntry from '../FilterListEntry/FilterListEntry';
import './FilterList.css';

// List options for 1 filter
function FilterList(props) {

    let options = props.optionsEntry;

    return (
        <div className="filter-list">
            <ul className="pageList">
                {
                    options.map((option, i) => {
                        return <FilterListEntry key={i} text={option} checked={props.activeList.includes(option)} checkTheList={props.checkTheList} />
                    })
                }
            </ul>
        </div>
    )
}

export default FilterList;