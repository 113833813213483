import React, { useEffect } from 'react';
import './NutritionWidget.css';

import NutritionStat from '../NutritionStat/NutritionStat';

// Widget displaying nutrition infos
function NutritionWidget(props) {
    const [error, setError] = React.useState(null);
    const [isLoaded, setIsLoaded] = React.useState(false);

    const defaultNutritions = [
        { name: "Calories", amount: "0", dv: 2000 },
        { name: "Carbs", amount: "0g", dv: 300 },
        { name: "Fat", amount: "0g", dv: 65 },
        { name: "Proteins", amount: "0g", dv: 50 }
    ]
    const [nutritions, setNutritions] = React.useState(defaultNutritions);

    let content;

    // Nutrition API call
    useEffect(() => {
        fetch("https://api.spoonacular.com/recipes/" + props.recipeId + "/nutritionWidget.json?apiKey=" + process.env.REACT_APP_API_KEY_NUTRICIONS)
            .then(res => res.json())
            .then(
                (result) => {
                    let newNutritions = [...nutritions];
                    newNutritions[0].amount = result.calories;
                    newNutritions[1].amount = result.carbs;
                    newNutritions[2].amount = result.fat;
                    newNutritions[3].amount = result.protein;

                    setIsLoaded(true);
                    setNutritions(newNutritions);
                },
                (error) => {
                    setIsLoaded(true);
                    setError(error);
                }
            )
        // eslint-disable-next-line
    }, [props.recipeId])

    if (error) {
        content = <div>No results.</div>
    } else if (!isLoaded) {
        content = <div>Loading...</div>
    } else {
        content = (
            <>
                {
                    nutritions.map((stat, i) => {
                        return <NutritionStat stat={stat} key={i} />
                    })
                }
            </>
        )
    }

    return (
        <div className="nutrition-widget">
            { content}
        </div>
    )
}

export default NutritionWidget;