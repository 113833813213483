import React from 'react';
import './NoRouteMatch.css';

// Displaying error when there is no match for a route (404)
function NoRouteMatch(props) {

    let code = 404;
    if (props.code) code = props.code;

    const hundreds = Math.floor(code / 100);
    const units = code - hundreds * 100;

    return (
        <div id="no-route-match">
            <div className="code">
                <p>{hundreds}</p>
                <img src="/img/icon.png" alt=""/>
                <p>{units}</p>
            </div>
            <p>Oops! Something went wrong!</p>
        </div>
    )
}

export default NoRouteMatch;