import React, { useEffect } from 'react';
import './MainPage.css';

import RecipeSearch from '../RecipeSearch/RecipeSearch';
import RecipeGrid from '../RecipeGrid/RecipeGrid';
import NoRouteMatch from "../NoRouteMatch/NoRouteMatch";

// Landing page with search and suggestions based on preferences
function MainPage() {
    function parseSimilarRecipes(recipes) {
        let newRecipes = []
        // Choose 12 random new recipes
        for (let i = 0; i < (recipes.length >= 12 ? 12 : recipes.length); i++) {
            const chosenRecipeId = Math.floor(Math.random() * recipes.length);
            newRecipes.push(recipes[chosenRecipeId])
            recipes.splice(favorites.indexOf(chosenRecipeId), 1);
        }

        // Add image property to recipe
        newRecipes = (newRecipes.map((recipe) => {
            recipe.image = "https://spoonacular.com/recipeImages/" + recipe.id + "-636x393.jpg"
            return recipe
        }))
        return newRecipes
    }

    const [prefListError, setPrefListError] = React.useState(null);
    const [prefList, setPrefList] = React.useState([null, null, null, null]);
    const [similarListError, setSimilarListError] = React.useState(null);
    const [similarList, setSimilarList] = React.useState([null, null, null, null]);
    const [favorites] = React.useState(localStorage["favorites"] ? JSON.parse(localStorage["favorites"]) : []);
    const [similarRecipeNumber] = React.useState(Object.keys(favorites).length !== 0 ? 50 : 0)
    const [similarRecipeId] = React.useState(Object.keys(favorites).length !== 0 ? favorites[(Math.floor(Math.random() * favorites.length))].id : 0)

    // Here the preferences to create a custom search. To forge the query : RecipeSearch (searchForge)
    let cuisine = '';
    let intolerances = '';
    let diet = '';
    const queries = localStorage["preferences"] ? JSON.parse(localStorage["preferences"]) : [];
    for (let i = 0; i < queries.length; ++i) {
        switch (queries[i].parameter) {
            case "cuisine":
                cuisine += "," + queries[i].name;
                break;
            case "intolerances":
                intolerances += "," + queries[i].name;
                break;
            case "diet":
                diet += "," + queries[i].name;
                break;
            default:
                break;
        }
    }
    const searchQuery = "cuisine=" + cuisine.substring(1) + "&intolerances=" + intolerances.substring(1) + "&diet=" + diet.substring(1);

    useEffect(() => {
        document.title = `Home | Dans le meal`;
    }, [])

    useEffect(() => {
        fetch("https://api.spoonacular.com/recipes/complexSearch?number=50&sort=random&" + searchQuery + "&instructionsRequired=true&addRecipeInformation=true&apiKey=" + process.env.REACT_APP_API_KEY_PREFERENCES)
            .then(res => {
                if (res.status === 200) {
                    return Promise.resolve(res)
                }
                else {
                    return Promise.reject(new Error(res.status))
                }
            })
            .then(res => res.json())
            .then(res => {
                setPrefList(res.results.slice(0, 12));
            })
            .catch(err => {
                setPrefListError(err.message)
            })
    }, [searchQuery])

    useEffect(() => {
        fetch("https://api.spoonacular.com/recipes/" + similarRecipeId + "/similar?number=" + similarRecipeNumber + "&apiKey=" + process.env.REACT_APP_API_KEY_SIMILAR)
            .then(res => {
                if (res.status === 200) {
                    return Promise.resolve(res)
                }
                else {
                    return Promise.reject(new Error(res.status))
                }
            })
            .then(res => res.json())
            .then(results => {
                similarRecipeNumber === 0 ? setSimilarList([]) : setSimilarList(parseSimilarRecipes(results));
            })
            .catch(err => {
                setSimilarListError(err.message);
            })
        // eslint-disable-next-line
    }, [similarRecipeId])

    if (prefListError || prefList === undefined || similarListError || similarList === undefined) {
        return <NoRouteMatch code={prefListError || similarList} />
    } 
    else {

        return (
            <div id="main-page">
                <div className="landing-picture">
                    <img src="/img/background.png" alt="Fruits Background" />
                    <h1>Don't wait to eat well!</h1>
                </div>

                <RecipeSearch searchQuery={""} />

                <RecipeGrid title="My Suggestions" recipeList={prefList} />
                {
                    similarRecipeNumber !== 0 && <RecipeGrid title="You may also like" recipeList={similarList} />
                }
            </div>
        )
    }
}

export default MainPage;