import React, { useState, useEffect } from 'react';
import PreferenceChoice from '../PreferenceChoice/PreferenceChoice';
import './Preferences.css';

import cuisines from '../../assets/preferences/cuisines.json'
import intolerances from '../../assets/preferences/intolerances.json'
import diets from '../../assets/preferences/diets.json'

// Settings page for user preferences
function Preferences() {

    // eslint-disable-next-line
    const [preferences, setPreferences] = useState(JSON.parse(localStorage["preferences"]));

    function updatePreferences() {
        setPreferences(JSON.parse(localStorage["preferences"]));
    }

    useEffect(() => {
        document.title = `Preferences | Dans le meal`;
    }, [])

    return (
        <div id="preferences">
            <h2>My Preferences</h2>

            {
                <>
                    <PreferenceChoice type={cuisines} selectedPreferences={preferences} updatePreferences={updatePreferences} step={1} />
                    <PreferenceChoice type={intolerances} selectedPreferences={preferences} updatePreferences={updatePreferences} step={2} />
                    <PreferenceChoice type={diets} selectedPreferences={preferences} updatePreferences={updatePreferences} step={3} />
                </>
            }
        </div>
    )
}

export default Preferences;