import React from 'react';
import { useHistory } from 'react-router-dom';
import './BackButton.css';

import Button from '../Button/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLongArrowAltLeft } from '@fortawesome/free-solid-svg-icons';

// Page displaying complete recipe
function BackButton() {

    let history = useHistory();

    return (
        <div className="back-button" onClick={history.goBack}>
            <FontAwesomeIcon icon={faLongArrowAltLeft} />
            <Button text="Back"/>
        </div>
    )
}

export default BackButton;