import React from 'react';
import './IngredientListEntry.css';

function IngredientListEntry(props) {
    let ingredient;

    if (props.stat.amount !== '' && props.stat.unit !== '' && props.stat.name !== '') {
        ingredient = (props.stat.amount + ' ' + props.stat.unit + ' ' + props.stat.name);
    } else {
        ingredient = props.stat.originalString;
    }

    return (
        <div className="ingredient-list-entry">
            <p>{ ingredient }</p>
        </div>
    )
}

export default IngredientListEntry;