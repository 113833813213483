import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import './Navbar.css';

import NavbarEntry from '../NavbarEntry/NavbarEntry';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faTimes } from '@fortawesome/free-solid-svg-icons';

function Navbar() {

    const [ isOpaque, setIsOpaque ] = useState(window.pageYOffset > 100);
    const [ mobileMenuOpen, setMobileMenuOpen ] = useState(false);

    const scrollListener = () => {
        if (window.pageYOffset > 100) {
            setIsOpaque(true)
        }
        else if ( window.pageYOffset <= 100) {
            setIsOpaque(false)
        }
    }

    const handleClick = () => {
        if (mobileMenuOpen) setMobileMenuOpen(false);
    }

    useEffect(() => {
        window.onscroll = scrollListener
    }, [])

    return (
        <div className={`navbar${isOpaque ? " opaque" : ""}`}>
            <Link to="/">
                <img className="logo" src="/img/logo.png" alt="Dans le Meal Logo"/>
            </Link>

            <div className={`navbar-entries${mobileMenuOpen ? " open" : ""}`}>
                <NavbarEntry link="/" text="Home" onClick={handleClick} />
                <NavbarEntry link="/preferences" text="Preferences" onClick={handleClick} />
                <NavbarEntry link="/favorites" text="My Favorites" onClick={handleClick} designed={true} />
            </div>

            <div className="navbar-mobile-button">
                <FontAwesomeIcon icon={mobileMenuOpen ? faTimes : faBars} onClick={() => setMobileMenuOpen(!mobileMenuOpen)}/>
            </div>
        </div>
    )
}

export default Navbar;