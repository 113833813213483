import React from 'react';
import { Link } from 'react-router-dom';
import './RecipeCard.css';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClock } from '@fortawesome/free-regular-svg-icons';

// Preview of a recipe before clicking on it
// Pour le recipeTime il y est dans la page du recipe mais pas dispo dans la recherche... déso
function RecipeCard(props) {

    if (props.recipe === null) {
        return (
                <div className="recipe-card loading">
                    <div className="card-img"></div>
    
                    <div className="card-infos"></div>
                </div>
        )
    }

    return (
        <Link to={"/recipe/" + + props.recipe.id} >
            <div className="recipe-card" title={props.recipe.title}>
                <div className="card-img">
                    <img src={props.recipe.image} alt="Dish" />
                </div>

                <div className="card-infos">
                    <p>{props.recipe.title}</p>
                    <p><FontAwesomeIcon icon={faClock} />{Math.floor(props.recipe.readyInMinutes / 60)}h {props.recipe.readyInMinutes % 60}m</p>
                </div>
            </div>
        </Link>
    )
}

export default RecipeCard;