import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import './SearchPage.css';

import RecipeGrid from '../RecipeGrid/RecipeGrid';
import RecipeSearch from '../RecipeSearch/RecipeSearch';
import NoRouteMatch from "../NoRouteMatch/NoRouteMatch";

// Page displaying results when searching
function SearchPage() {
    const [error, setError] = React.useState(null);
    const [recipeList, setRecipeList] = React.useState([null, null, null, null]);

    let { searchQuery } = useParams();
    let searchQueryTitle = `Search for: ${searchQuery.split('&')[0].split('=')[1]}`
    if (!searchQuery) {
        searchQuery = ''
    }

    useEffect(() => {
        document.title = `${searchQueryTitle} | Dans le meal`;
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        fetch("https://api.spoonacular.com/recipes/complexSearch?number=12&" + searchQuery + "&instructionsRequired=true&addRecipeInformation=true&apiKey=" + process.env.REACT_APP_API_KEY_SEARCH)
            .then(results => {
                if (results.status === 200) {
                    return Promise.resolve(results)
                }
                else {
                    return Promise.reject(new Error(results.status))
                }
            })
            .then(results => results.json())
            .then(results => {
                setRecipeList(results.results);
            })
            .catch(err => {
                setError(err.message);
            })
    }, [searchQuery])

    if (error || recipeList === undefined) {
        return <NoRouteMatch code={error}/>
    } else {
        return (
            <div id="search">
                <RecipeSearch searchQuery={searchQuery} />

                <RecipeGrid title={searchQueryTitle} recipeList={recipeList} />
            </div>
        )
    }
}

export default SearchPage;